<template>
    <div>
        <img :src="profilePic" v-if="profilePic" height="33" width="33" style="border-radius: 4px;">
    </div>
</template>
<script>
export default{
    name:"ProfilePicture",
    props:['profilePic'],
    components: {
        ProfilePlaceHolder: () => import(/* webpackChunkName: "profilePlaceHolder" */ "@/components/SvgImages/Profilesvg.vue"),
    }
}
</script>
